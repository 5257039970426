import React from 'react'
import { Link } from 'gatsby'
import SVGLogo from '../../assets/image/svg/smaller-footprint-.svg'

const Logo = ({ className = '', ...rest }) => {
    return (
        <Link to="/" className={`${className}`} {...rest}>
            <SVGLogo />
        </Link>
    )
}

export default Logo
