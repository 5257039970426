import React from 'react';
import { Link } from 'gatsby';

import imgLB from '../../assets/image/stric-main-dark.png';
import imgLW from '../../assets/image/stric-main-white.png';
import imgFS from '../../assets/image/inner-page/png/yellow-footer-shape.png';

const Footer = ({ className, ...rest }) => {
    return (
        <>
            <div
                className={`bg-dark-cloud pt-13 pt-lg-27 pb-7 dark-mode-texts position-relative ${className}`}
                {...rest}
            >
                <div className="container">
                    <div className="row pb-lg-25">
                        <div className="col-lg-4 col-md-5 col-md-3 col-xs-8">
                            <div className="pr-xl-20 mb-11 mb-lg-0 mt-lg-5">
                                <div className="brand-logo mb-5">
                                    <Link to="https://stric.com.au/">
                                        <img
                                            className="mx-auto mx-0 light-version-logo default-logo"
                                            src={imgLB}
                                            alt=""
                                        />
                                        <img
                                            src={imgLW}
                                            alt=""
                                            className="dark-version-logo mx-auto mx-0"
                                        />
                                    </Link>
                                </div>
                                <p className="font-size-5 mb-0 text-bali-gray pr-sm-10 pr-md-0">
                                    We’re an Australian internet monitoring
                                    company.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 offset-xl-1">
                            <div className="row">
                                {/* Single Widgets */}
                                <div className="col-md-3 col-xs-6">
                                    <div className="mb-10 mb-lg-0">
                                        <h4 className="font-size-6 font-weight-normal mb-8 text-bali-gray">
                                            Boring stuff
                                        </h4>
                                        <ul className="list-unstyled">
                                            <li className="mb-6">
                                                <Link
                                                    to="/legal/terms"
                                                    className="font-size-5 text-lily-white gr-hover-text-blue-3"
                                                >
                                                    Terms and Conditions
                                                </Link>
                                            </li>
                                            <li className="mb-6">
                                                <Link
                                                    to="/legal/privacy"
                                                    className="font-size-5 text-lily-white gr-hover-text-blue-3"
                                                >
                                                    Privacy Policy
                                                </Link>
                                            </li>
                                            <li className="mb-6">
                                                <Link
                                                    to="/legal/acceptable-use"
                                                    className="font-size-5 text-lily-white gr-hover-text-blue-3"
                                                >
                                                    Acceptable Use Policy
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/* End Single Widgets */}
                                {/* Single Widgets */}
                                <div className="col-md-3 col-xs-6">
                                    <div className="mb-10 mb-lg-0">
                                        <h4 className="font-size-6 font-weight-normal mb-8 text-bali-gray">
                                            Other
                                        </h4>
                                        <ul className="list-unstyled">
                                            <li className="mb-6">
                                                <Link
                                                    to="/"
                                                    className="font-size-5 text-lily-white gr-hover-text-blue-3"
                                                >
                                                    For Individuals
                                                </Link>
                                            </li>
                                            <li className="mb-6">
                                                <Link
                                                    to="/point"
                                                    className="font-size-5 text-lily-white gr-hover-text-blue-3"
                                                >
                                                    Footprint - Point
                                                </Link>
                                            </li>
                                            <li className="mb-6">
                                                <Link
                                                    to="/trace"
                                                    className="font-size-5 text-lily-white gr-hover-text-blue-3"
                                                >
                                                    Footprint - Trace
                                                </Link>
                                            </li>
                                            <li className="mb-6">
                                                <Link
                                                    to="/opt-out"
                                                    className="font-size-5 text-lily-white gr-hover-text-blue-3"
                                                >
                                                    Don't sell my Data
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/* End Single Widgets */}
                                {/* Single Widgets */}
                                <div className="col-lg-6 col-md-5 col-xs-8">
                                    <div className="mb-10 mb-lg-0 mr-xl-12">
                                        <h4 className="font-size-6 font-weight-normal mb-8 text-bali-gray">
                                            Who's behind Footprint?
                                        </h4>
                                        <p className="font-size-5 mb-0 text-lily-white">
                                            We're built by the team at Stric®.
                                            <br />
                                            Using the power of their internet
                                            monitoring tools, we're able to
                                            deliver you what Stric® offers
                                            organisations. Are you an
                                            organisation?
                                        </p>
                                        <div className="mt-7">
                                            <Link
                                                to="https://stric.com.au"
                                                target="_blank"
                                                className="font-size-5 d-block text-golden-yellow mb-0"
                                                href="https://stric.com.au"
                                            >
                                                Learn more about Stric®
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                {/* End Single Widgets */}
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center pt-10 border-top">
                        <div className="col-lg-6 text-center text-lg-left">
                            <div className="copyright">
                                <p className="mb-0 font-size-3 text-bali-gray">
                                    © Stric® {new Date().getFullYear()}.{' '}
                                    <i>Stric</i> is a registered trademark.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 text-center text-lg-right">
                            <div className="footer-right mt-5 mt-lg-0">
                                <ul className="list-unstyled d-flex align-items-center justify-content-center justify-content-lg-end mb-0">
                                    <li>
                                        <Link
                                            to="https://www.facebook.com/footprint.by.stric"
                                            className="text-white gr-hover-text-blue-3 ml-7"
                                            target="_blank"
                                        >
                                            <i className="fab fa-facebook-f" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="https://linkedin.com/company/footprint-by-stric"
                                            className="text-white gr-hover-text-blue-3 ml-7"
                                            target="_blank"
                                        >
                                            <i className="fab fa-linkedin" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="https://github.com/stric-co"
                                            className="text-white gr-hover-text-blue-3 ml-7"
                                            target="_blank"
                                        >
                                            <i className="fab fa-github" />
                                        </Link>
                                    </li>
                                    <li className="ml-7 mt-1">
                                        <iframe
                                            src="https://status.getfootprint.com.au/badge?theme=dark"
                                            height="30"
                                            width="175"
                                            frameborder="0"
                                        ></iframe>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-yellow-shape-img">
                    <img src={imgFS} alt="" />
                </div>
            </div>
        </>
    );
};

export default Footer;
