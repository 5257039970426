export const menuItems = [
    {
        name: 'business',
        label: 'For Business',
        items: [
            {
                name: 'point',
                label: 'Point - Point in time reports'
            },
            {
                name: 'trace',
                label: 'Trace - People-centric data tool'
            }
        ]
    },
    {
        name: 'faq',
        label: 'FAQ'
    },
    {
        name: 'contact',
        label: 'Contact'
    },
    {
        name: 'https://blog.getfootprint.com.au/',
        label: 'Blog',
        isExternal: true
    },
    {
        name: 'https://help.getfootprint.com.au/',
        label: 'Support',
        isExternal: true
    }
];
