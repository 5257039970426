exports.components = {
  "component---src-breach-details-js": () => import("./../../../src/breach-details.js" /* webpackChunkName: "component---src-breach-details-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cancel-js": () => import("./../../../src/pages/cancel.js" /* webpackChunkName: "component---src-pages-cancel-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-get-report-js": () => import("./../../../src/pages/get-report.js" /* webpackChunkName: "component---src-pages-get-report-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-acceptable-use-js": () => import("./../../../src/pages/legal/acceptable-use.js" /* webpackChunkName: "component---src-pages-legal-acceptable-use-js" */),
  "component---src-pages-legal-privacy-js": () => import("./../../../src/pages/legal/privacy.js" /* webpackChunkName: "component---src-pages-legal-privacy-js" */),
  "component---src-pages-legal-terms-js": () => import("./../../../src/pages/legal/terms.js" /* webpackChunkName: "component---src-pages-legal-terms-js" */),
  "component---src-pages-legal-verification-js": () => import("./../../../src/pages/legal/verification.js" /* webpackChunkName: "component---src-pages-legal-verification-js" */),
  "component---src-pages-opt-out-js": () => import("./../../../src/pages/opt-out.js" /* webpackChunkName: "component---src-pages-opt-out-js" */),
  "component---src-pages-point-index-js": () => import("./../../../src/pages/point/index.js" /* webpackChunkName: "component---src-pages-point-index-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-trace-index-js": () => import("./../../../src/pages/trace/index.js" /* webpackChunkName: "component---src-pages-trace-index-js" */)
}

